import Vue from 'vue'

const Swal = Vue.swal
const toastDefaults = {
  toast: true,
  position: 'top-end',
  icon: 'success',
  timer: 5000,
  timerProgressBar: true,
  showConfirmButton: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
}

const fire = function (options) {
  return Swal.fire(options)
}
const toast = function (options) {
  return fire({
    ...toastDefaults,
    ...options
  })
}

const confirm = function (options) {
  return fire({
    showCancelButton: true,
    icon: 'question',
    ...options
  })
}

const confirmAction = function (options, action) {
  return confirm({
    text: `Please confirm the action: ${action.toUpperCase()}.`,
    ...options
  })
}

// TODO: delete this method after realisation
const wip = function (options) {
  return fire({
    showCancelButton: true,
    icon: 'warning',
    title: 'WIP. Alert Title',
    text: 'WIP. This Action is not available right now, sorry.',
    ...options
  })
}

const Alert = {
  toast,
  fire,
  wip,
  confirm,
  confirmAction
}

export default Alert
