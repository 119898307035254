const defaults = {
  hidden: false,
  errors: [],
  getValue: function () { return this.value }
}
const input = function (value) {
  return {
    ...defaults,
    value: value || null
  }
}
const boolean = function (value) {
  return {
    ...defaults,
    type: 'boolean',
    value: (typeof value === 'boolean') ? value : false,
    getValue: function () {
      return this.value ? 1 : 0
    }
  }
}
const number = function (value) {
  return {
    ...defaults,
    type: 'number',
    value: (typeof value === 'number') ? value : null,
    getValue: function () {
      return this.value ? Number(this.value) : null
    }
  }
}
const file = function (value) {
  return {
    ...defaults,
    type: 'file',
    value: value instanceof File ? value : null
  }
}
const multiInput = function (value) {
  return {
    ...defaults,
    type: 'array',
    value: Array.isArray(value) ? value : [],
    getValue: function () {
      return this.value.map((val) => {
        const value = val.value.getValue ? val.value.getValue() : val.value
        return { key: val.key, value: value }
      })
    }
  }
}
const select = function (value, key) {
  return {
    ...defaults,
    type: 'select',
    key: typeof key === 'string' ? key : 'id',
    value: (value === null || Object.isObject(value)) ? value : null,
    getValue: function () {
      return this.value ? this.value[this.key] : this.value
    }
  }
}
const multiSelect = function (value, key) {
  return {
    ...defaults,
    type: 'multiselect',
    key: typeof key === 'string' ? key : 'id',
    value: Array.isArray(value) ? value : [],
    getValue: function () {
      return this.value ? this.value.map((val) => val[this.key]) : this.value
    }
  }
}
const date = function (value) {
  return {
    ...defaults,
    type: 'date',
    value: (typeof value === 'string') || (value instanceof Date) ? value : null,
    getValue: function () {
      return this.value
    }
  }
}
const dateRange = function (value) {
  return {
    ...defaults,
    type: 'daterange',
    value: Array.isArray(value) ? value : null,
    getValue: function () {
      if (!Array.isArray(this.value)) return null
      return {
        start: this.value[0] || null,
        end: this.value[1] || null
      }
    }
  }
}

const types = {
  input,
  boolean,
  number,
  file,
  multiInput,
  select,
  multiSelect,
  date,
  dateRange
}

export default types
