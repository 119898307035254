<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <input :class="classes" v-bind="attributes" v-model="model.value"/>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    model: { type: Object, default: undefined },
    name: { type: String, required: true },
    type: { type: String, default: 'text' },
    size: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    pattern: { type: String, default: undefined },
    auto: { type: String, default: undefined },
    step: { type: Number, default: undefined },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined }
  },
  computed: {
    classes () {
      const classes = ['form-control']

      if (this.size) classes.push('input-' + this.size)

      return classes.join(' ')
    },
    attributes () {
      return {
        type: this.type,
        id: this.name,
        name: this.name,
        placeholder: this.placeholder,
        readonly: this.readonly,
        disabled: this.attrDisabled,
        required: !this.attrDisabled && this.required,
        autocomplete: this.auto || false,
        pattern: this.pattern,
        ...this.minmax
      }
    },
    attrDisabled () { return this.readonly ? true : this.disabled },
    minmax () {
      return this.type === 'number'
        ? { min: this.min, max: this.max, step: this.step }
        : { minlength: this.min, maxlength: this.max }
    }
  }
}
</script>
