<template>
  <form :method="method" :action="url" @submit.prevent="submit">
    <box :title="title" :loading="!loaded || loading" :open="loaded && open">
      <template slot="pre-content">
        <slot name="pre-content"/>
      </template>
      <template slot="content">
        <slot name="content"/>
      </template>
      <template slot="post-content" v-if="$slots['post-content']">
        <slot name="post-content"/>
      </template>
      <template slot="post-content" v-else>
        <btn type="submit" :disabled="disabledSubmit" text>{{ isEdit ? 'Save' : 'Create' }}</btn>
      </template>
    </box>
  </form>
</template>

<script>
import Alert from '@/modules/utils/Alert'
import Btn from '@/views/components/simple/Btn'
import Form from '@/modules/utils/Form'
import { mapActions } from 'vuex'

export default {
  name: 'BoxForm',
  components: {
    Btn
  },
  props: {
    title: { type: String, default: 'Info' },
    loaded: { type: Boolean, default: true },
    loader: { type: Boolean, default: false },
    disabledSubmit: { type: Boolean, default: false },
    url: { type: String, required: true },
    action: { type: String, required: true },
    dataProp: { type: String, required: true },
    respProp: { type: String, default: undefined },
    redirect: { type: [String, Function], default: undefined },
    preSendCallable: { type: Function, default: undefined }
  },
  data () {
    return {
      open: true,
      loading: false,
      actions: {
        create: 'post',
        edit: 'put'
      }
    }
  },
  computed: {
    formData () { return this.$parent[this.dataProp] },
    isEdit () { return this.action.toLowerCase() === 'edit' },
    method () { return this.actions[this.action.toLowerCase()] ?? 'post' }
  },
  created () {
  },
  methods: {
    ...mapActions({
      addHighlightedId: 'app/addHighlightedId'
    }),
    submit () {
      this.preSendCallable
        ? this.preSendCallable(() => { this.sendData() })
        : this.sendData()
    },
    sendData () {
      this.beforeRequest()

      const data = Form.data.make(this.formData)

      this.$http
        .request({
          url: this.url,
          method: this.method,
          data: data,
          headers: data instanceof FormData
            ? { 'Content-Type': 'multipart/form-data' }
            : {}
        })
        .then(this.processResponse)
        .catch(this.processError)
        .finally(this.afterRequest)
    },
    hasFiles () {
      return Object.keys(this.formData).some((key) => !this.formData[key].hidden && this.formData[key].type === 'file')
    },
    getHeaders () {
      return this.hasFiles() ? { 'Content-Type': 'multipart/form-data' } : {}
    },
    getData () {
      const data = this.hasFiles() ? new FormData() : {}

      Object.keys(this.formData).forEach((key) => {
        const datum = this.formData[key]
        const value = datum.hidden
          ? null
          : datum.getValue
            ? datum.getValue()
            : ('value' in datum)
              ? datum.value
              : datum

        if (value === null || value === undefined) return

        if (data instanceof FormData) {
          datum.type === 'file' ? data.append(key, value, value.name) : data.append(key, value)
        } else {
          data[key] = value
        }
      })

      return data
    },
    getFormData () {
      const data = new FormData()

      Object.keys(this.formData).forEach((key) => {
        const datum = this.formData[key]
        const value = this.getDatumValue(datum)

        if (value === null || value === undefined) return

        datum.type === 'file'
          ? data.append(key, value, value.name)
          : data.append(key, value)
      })

      return data
    },
    getJsonData () {
      return this.$helpers.Obj.map(this.formData, (datum) => this.getDatumValue(datum))
    },
    getDatumValue (datum) {
      return datum.getValue ? datum.getValue() : ('value' in datum) ? datum.value : datum
    },
    beforeRequest () {
      this.$emit('request-before')

      if (this.loader) this.loading = true

      for (const key in (this.formData || {})) {
        this.formData[key].errors = []
      }
    },
    afterRequest () {
      if (this.loader) this.loading = false

      this.$emit('request-after')
    },
    processResponse (res) {
      const { data } = res

      if (this.respProp) this.$parent[this.respProp] = data

      this.$emit('request-success', data)

      if (this.redirect) {
        const { data: { id } } = data

        this.addHighlightedId(id)

        this.$router.push(
          typeof this.redirect === 'function'
            ? this.redirect(data)
            : { name: this.redirect }
        )
      }
    },
    processError (err) {
      const {
        response: {
          status: code,
          data: { message, errors }
        }
      } = err

      Alert.toast({
        icon: 'error',
        timer: 7000,
        title: `[${code}] ${message}`
      })

      this.$emit('request-error', message)

      for (const key in (errors || {})) {
        const k = key.split('.')[0]

        if (k in this.formData) this.formData[k].errors = errors[key]
      }

      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>
