const hasFiles = function (formData) {
  return Object.keys(formData).some((key) => {
    return !formData[key].hidden && formData[key].type === 'file'
  })
}

const getValue = function (datum) {
  if (datum.hidden) return null

  return datum.getValue
    ? datum.getValue()
    : ('value' in datum) ? datum.value : datum
}

const make = function (formData) {
  const data = hasFiles(formData) ? new FormData() : {}

  Object.keys(formData).forEach((key) => {
    const datum = formData[key]
    const value = getValue(datum)

    if (value === null || value === undefined) return

    if (data instanceof FormData) {
      datum.type === 'file' && value instanceof File
        ? data.append(key, value, value.name)
        : data.append(key, value)
    } else {
      data[key] = value
    }
  })

  return data
}

const data = {
  make
}

export default data
