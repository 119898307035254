<template>
  <div class="form-group" :class="{ 'required': required, 'has-error': errors.length }">
    <label :for="name">{{ titleValue }}</label>
    <div class="additional-info" v-if="$slots['info']">
      <slot name="info"/>
    </div>
    <form-input v-bind="$props" :placeholder="placeholderValue"/>
    <p class="help-block info" v-if="$slots['help']">
      <slot name="help"/>
    </p>
    <p class="help-block error" v-for="(error, i) in errors" :key="`${name}-error-${i}`">{{ error }}</p>
  </div>
</template>

<script>
import FormInput from '@/views/components/form/FormInput'

export default {
  name: 'FormGroupInput',
  components: {
    FormInput
  },
  props: {
    title: { type: String, default: '' },
    ...FormInput.props
  },
  computed: {
    errors () { return this.model.errors },
    titleValue () { return this.title ? this.title : this.$helpers.Str.titleCase(this.name) },
    placeholderValue () { return typeof this.placeholder === 'undefined' ? this.titleValue : this.placeholder }
  }
}
</script>
